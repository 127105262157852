export enum TRACKING_EVENTS_TYPES {
  ACCESS_MAIL = 'ACCESS_MAIL',
  ACCESS_CALENDAR = 'ACCESS_CALENDAR',
  ACCESS_LAST_LESSON_HP = 'ACCESS_LAST_LESSON_HP',
  ACCESS_NOTIFICATION = 'ACCESS_NOTIFICATION',
  ACCESS_TRANSCRIPT = 'ACCESS_TRANSCRIPT',
  APPOINTMENT = 'APPOINTMENT',
  ASSIGNMENT_ATTACHMENT_DOWNLOAD = 'ASSIGNMENT_ATTACHMENT_DOWNLOAD',
  AUDIO_PLAY = 'AUDIO_PLAY',
  CREATE_HIGHLIGHT_NOTE = 'CREATE_HIGHLIGHT_NOTE',
  END_SEQUENCE = 'END_SEQUENCE',
  FEEDBACK_CAMPAIGN_ANSWER = 'FEEDBACK_CAMPAIGN_ANSWER',
  FEEDBACK_CAMPAIGN_DISMISS = 'FEEDBACK_CAMPAIGN_DISMISS',
  LESSON_SEQUENCE_DOWNLOAD = 'LESSON_SEQUENCE_DOWNLOAD',
  LIVE_EVENT_CONNEXION = 'LIVE_EVENT_CONNEXION',
  LIVE_EVENT_REPLAY = 'LIVE_EVENT_REPLAY',
  TEACHER_CONTACT = 'TEACHER_CONTACT',
}
